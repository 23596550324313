/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Image from "./image"
import styled from "styled-components"

const rowStyle = (length) => {
    let total_columns = length;
    let proportion = 1 / total_columns;

    
    let shared = {

    };
    switch (length) {
        case 2:
            return {
              ...shared
            };
        default:
          return {
            ...shared
          };

    }
}

const Row =  styled.div` 
display:grid;
column-gap: 2vw;

width: 100%;

padding: 0px 10px;
margin-bottom: 2vw;

grid-template-columns: repeat(12, 1fr);

@media (max-width: 500px) {
grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 700px) {
grid-template-columns: repeat(2, 1fr);
}
` 

function ImageRow({ images,rowType,forceLength }) {
  
  let total_columns = forceLength ? forceLength : images.length;

  return (
  <Row className={rowType} >
    {images.map((image) => <Image key={image.url ? image.url : image.text} image={image} total_columns={total_columns} size={images.length} />)}
  </Row>
  )
}

ImageRow.defaultProps = {
  images: [],
}

ImageRow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
    rowType: PropTypes.string
}

export default ImageRow
