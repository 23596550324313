import React from "react"
import { useStaticQuery, graphql,Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import "../css/custom.css"
import styled from "styled-components"


var ImageOuterContainer = styled.div`
margin: 10px auto;
width: 100%;
position: relative;

grid-column-end: span ${props => props.span ? props.span : 3};
align-self: stretch;

transition: all 0.5s;
cursor: pointer;
div.img {
    height: 70vw;
}
  @media (min-width: 600px) {
  margin: 0px auto;  
  div.img {
    height: 30vw;
  }
  }
`

var ImageInnerContainer = styled.div`


`
var ImageFull = styled.div`
  
  background-size: 0 0;
  height:auto;
  img {
      max-height:80vh;
      margin-left:auto;
      margin-right:auto;
      display:block;
  }
  @media (min-width: 1000px) {
    height: 68vh;
    background-size: contain;
    
    img {
      display:none;
    
      
    }
  }
`
var ImageDetails = styled.div`

  position:relative;

  text-align:center;
  margin-top:20px;

  @media (min-width: 1000px) {
    padding:40px 40px 80px 40px;
    overflow:hidden;
    margin-top:0;
    background: white;
    box-sizing: border-box;
    border:1px solid black;
    opacity:1;
    text-align:left;
    left:0;right:0;top:0;bottom:0;
    position:absolute;
    opacity:0;
          transition: opacity 1s;
    &:hover {

      opacity:0.8;
    }
  }

.dimensions {
  position:absolute;
  bottom:40px;
}
.title {
  display:inline;
  padding-bottom:5px;
    padding-top:140px;
    @media (min-width: 1000px) {
    margin-bottom:30px;
    }
  
}
.materials {
  @media (min-width: 1000px) {
    padding-top:7px;
  }
  font-style:italic;
}
.dimensions {
  display:none;
    @media (min-width: 1000px) {
      display:block;
          line-height: 28px;
    }
  white-space: pre-wrap;  
}
.dimensions span {
  display:block;
  font-size:20px;
}`

const imageRender = ({image,total_columns, span}) => {

  let details = image.details?  image.details : undefined;
  let url = image.url;
  if(image.type == "stacked") {

return <div

    style={{
      margin: `0 auto`,
      width: "100%",
      gridColumnEnd: `span ${span}` ,
       "alignSelf": "stretch",
        "min-height": `30vh`,
      transition: "all 0.5s",
      cursor: "pointer", 
  
    }}
  ><div style={{width: "100%", 
          height:"30vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position:"relative",
          backgroundPosition: "center center",
                gridColumnEnd: `span ${span}` ,  
          backgroundImage: `url(${url})`}}>
      
      
        </div><div style={{
          textAlign:"left",
          padding:"20px"
      
        }}>{details}</div></div>
        
  }
  else if(image.type != "text" && image.size != "full") {

    
return <ImageOuterContainer
    className="object"
  ><div className="img" style={{width: "100%", 
      
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position:"relative",
          backgroundPosition: "center center",
                gridColumnEnd: `span ${span}` ,  
          backgroundImage: `url(${url})`}}>
      
        
        </div><ImageDetails>{details}</ImageDetails></ImageOuterContainer>
        
  }

  else if (image.type != "text" && image.size == "full") {
    return <ImageFull
        
        style={{
          margin: `0 auto`,
          width: "100%",
         "alignSelf": "start",
          padding: "2vh 2.5vh 0vh 0",
          transition: "all 0.5s",
          cursor: "pointer",
        
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
  
          backgroundImage:  `url(${url})`,
              gridColumnEnd: `span ${span}` ,
        }}
      ><img src={url} /></ImageFull>
            
      
  }
  if(image.type == "text") {
    
    let backgroundColor = image.subtype == "highlight" ? "rgba(60, 230, 200,1)": "transparent";
    
    return <div
        className="text-i"
        style={{
              gridColumnEnd: `span ${span}` ,
          margin: `0 auto`,
          width: "100%",
      

          position:"relative",
          color:"black",
          padding: image.subtype == "highlight" ? "2.5vh": 0,
          fontSize: image.subtype == "highlight" ? "36px": "36px",
          transition: "all 0.5s",
      
        }}
      ><div>
    {image.text}
    </div></div>
  }
}

const Image = ({image,total_columns}) =>  {
  let span = image.span? image.span : 12 / total_columns;
  return image.link ? <Link to={image.link} style={{gridColumnEnd: `span ${span}`}}>{imageRender({image,total_columns,span})}</Link> : imageRender({image,total_columns,span})
}
Image.propTypes = {
  image: PropTypes.object
}

Image.defaultProps = {
  images: {},
}


export default Image


