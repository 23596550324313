/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"




function Break() {
  
  return (
  <div style={{width:"100%",height:"2px", backgroundColor:"rgba(0,0,0,0)", "margin":"5vh 0px", boxSizing:"border-box"}}>
  </div>
  )
}

Break.defaultProps = {

}

Break.propTypes = {

}

export default Break
