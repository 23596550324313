import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'

import Layout from "../components/layout"

import ImageRow from "../components/image-row"
import Image from "../components/image"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import styled from "styled-components"

const gql_query = graphql`{
  news: craftAPI { entries(type: "news") {
    ... on CraftAPI_news_news_Entry {
      id
      slug
      title
      pageText
      website
      images {
        ... on CraftAPI_artworks_Asset {
          id
          img
          filename
          url
        }
      }
    }

}}}`

const Row = styled.div`
display:grid;
column-gap: 2vw;

width: 100%;
max-width:1200px;
margin-left:auto;
margin-right:auto;
white-space:break-spaces;
padding: 0px 10px;
margin-bottom: 8vw;

grid-template-columns: repeat(6, 1fr);

@media (max-width: 500px) {
grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 700px) {
grid-template-columns: repeat(2, 1fr);
}`


const NewsImage = styled.div`
grid-column-end: span 3

`

const NewsBody = styled.div`

grid-column-end: span 3

`

const news_rows = (news) => {

  let rows = news.map((item) => {
    let  image = {url: item.images[0].url }
    return <Link to={item.website}><Row><Image key={image.url ? image.url : image.text} image={image} total_columns="1" size='1' /><NewsBody><h2>{item.date}{item.title}</h2><div>{item.pageText}</div></NewsBody></Row></Link>
  })
  return rows

}
const hero = <Carousel  />
const News = () => (
        <StaticQuery query={gql_query} render={(data) => {


  return <Layout hero={undefined} intro={false}>
    <SEO title="Home" />
    <div style={{ }}>

  <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"120px", textAlign:"center"}}>News</h1>
      {news_rows(data.news.entries)}




    </div>
  </Layout>}} />
)

export default News
